import React from "react";
import PropTypes from "prop-types";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import {
  BlogCard,
  Button,
  Directiontext,
  Hero,
  SectionHeader,
} from "../../components/common";

import Updates from "../../assets/images/vectors/updates.svg";

function BlogPage({ location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Blog"
        path={location?.pathname}
      />

      <section className="mt-20 mb-8">
        <Hero
          subTitle="Vackground Blog"
          title="A Blog to Grow Visually"
          description="Learn how to grow with visuals, how to get love from your customers. Subscribe to get these updates in your e-mailbox."
        />
      </section>
      <section className="mb-32 max-w-4xl m-auto">
        <form className="flex items-center w-full gap-x-4">
          <div className="flex-1">
            <input
              placeholder="email@example.com"
              className="py-5 px-6 border border-ui-gray-plus-1 rounded-lg w-full bg-none bg-opacity-0"
            />
          </div>
          <div className="inline-block relative">
            <Button
              text="Get Updates in Mailbox"
              icon="vg-mail"
              hasMinWidth={false}
            />
          </div>
        </form>
        <div className="text-tiny pt-4">
          <div>
            <span /> I understand that Vackground can send me promotional emails
            and i can opt-out at any time
          </div>
        </div>
      </section>
      <section className="mb-32">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-x-8 gap-y-16 items-center">
          <BlogCard
            to="/blog/post"
            title="New Fabric Printing Pattern by Vackground Team"
          />
          <BlogCard
            to="/blog/post"
            title="Illustrated Patterns by Patricia Braune"
          />
          <BlogCard
            to="/blog/post"
            title="Creating Pattern Design Collections Your Way"
          />
          <BlogCard
            to="/blog/post"
            title="Celebrating Fun Patterns with Amelia Robson"
          />
          <BlogCard
            to="/blog/post"
            title="Creating A Sense of Wonder with Lindsay Hook"
          />
          <BlogCard
            to="/blog/post"
            title="Hand-Drawn Floral Patterns by Clare Martin"
          />
          <BlogCard
            to="/blog/post"
            title="15 Free Calendar Designs to Refresh Your Desktop & Smartphone"
          />
          <BlogCard
            to="/blog/post"
            title="Dreamy Textile Designs by Darja Miklauzic"
          />
          <BlogCard to="/blog/post" title="Free Graphic Design Software" />
          <BlogCard
            to="/blog/post"
            title="Writing A Reflective Artist’s Statement"
          />
          <BlogCard
            to="/blog/post"
            title="Geometric Pattern Designs by Alison Greyer"
          />
          <BlogCard
            to="/blog/post"
            title="Modern Floral Prints by Amanda Caronia"
          />
        </div>
        <div className="mt-24 flex items-center justify-center">
          <Button
            text="Load More Blogs"
            icon="vg-arrow-right"
            iconRotate={90}
          />
        </div>
      </section>
      <section className="mb-24">
        <div>
          <SectionHeader
            art
            artVector={Updates}
            title="Looking for Site & Product Updates?"
          />
        </div>
        <div className="flex items-center justify-center">
          <Directiontext text="View All Updates" />
        </div>
      </section>
    </Layout>
  );
}

BlogPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default BlogPage;
